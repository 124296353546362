<template>
    <section class="terms">
        <div class="d-flex justify-content-center">
            <div class="w-50">
                <div class="card">
                    <div class="card-body text-left">
                        <div class="container-fluid my-3 mx-2">
                            <h2>Syarat dan Ketentuan Penggunaan Soca</h2>
                            <div class="content-terms overflow-auto mt-3">
                                <div class="ketentuan-umum mt-4 mr-2">
                                    <h3>Ketentuan Umum</h3>
                                    <div class="pharagraph mt-2">
                                        <h4 class="font-weight-normal">Syarat dan ketentuan Aplikasi Soca ini merupakan perjanjian antara
                                            pengguna (“<b>Anda</b>” atau “<b>Pengguna</b>”) dan PT Jimmy Group Technology
                                            (“<b>Kami</b>” atau “<b>SOCA</b>”), sebuah perseroan terbatas yang didirikan dan
                                            beroperasi sah berdasarkan hukum Negasa Republik Indonesia dan
                                            berdomosili di Bandung, Jawa Barat, Indonesia.
                                        </h4>
                                    </div>
                                </div>
                                <div class="definisi mt-4 mr-2">
                                    <h3>Definisi</h3>
                                    <div class="pharagraph mt-2">
                                        <ul class="ml-4">
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Kebijakan Penggunaan yang Dapat Diterima”</b> berarti Kebijakan Penggunaan yang dapat diterima Soca yang berlaku dari waktu ke waktu dan dapat diakses di sini.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Kreator”</b> berarti orang yang membuat konten dan data yang diunggah ke Layanan Soca, termasuk namun tidak terbatas pada kuis, gambar, video, teks, pesan, informasi, avatar 3D, ruang 3D, umpan balik pengguna, dan konten lainnya.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Perjanjian”</b> adalah istilah kolektif yang mencakup Persyaratan ini, Kebijakan Penggunaan yang Dapat Diterima, Paket Layanan apa pun dan, jika berlaku, Perjanjian Perusahaan atau Langganan Konten.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Aplikasi”</b> berarti perangkat lunak/aplikasi web Soca.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Langganan”</b> berarti berbagai layanan premium yang tersedia untuk dibeli dengan berlangganan Paket Layanan yang dipilih oleh setiap pengguna.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b></b> berarti semua merek dagang Soca, merek layanan, nama dagang, logo, nama domain, dan fitur lain dari merek Soca.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Lisensi”</b> berarti lisensi yang diberikan kepada Anda sesuai dengan Ketentuan ini.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Platform”</b> berarti Situs Web dan Aplikasi Soca di mana Layanan Soca tersedia.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Konten Pengguna Publik”</b> berarti, karena beberapa Layanan Soca didasarkan pada Konten Pengguna dan bergantung pada pengguna yang menyumbangkan konten ke Layanan, Konten di mana Pengguna, atas pilihan mereka sendiri dan dengan secara aktif mengaktifkan tombol “publik” atau “terbitkan”, membuat Konten Pengguna tertentu, seperti kuis Soca, tersedia untuk umum.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Sumber Daya”</b> berarti informasi, sumber daya, layanan, produk, dan alat yang disediakan untuk Anda di Layanan Soca.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Layanan”</b> berarti layanan Soca, termasuk perangkat lunak apa pun, yang Anda langgani berdasarkan Ketentuan ini.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Syarat”</b> berarti syarat dan ketentuan umum yang berlaku untuk penggunaan Anda atas Layanan Soca.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Pihak Ketiga”</b> berarti orang atau situs web dan layanan yang diberikan oleh pihak ketiga yang terintegrasi dalam Layanan dan Sumber Daya untuk menyediakan fitur, konten, produk, dan/atau layanan tertentu untuk Anda.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Uji Coba”</b> berarti uji coba langganan Paket Layanan berbayar atau Langganan Konten untuk jangka waktu tertentu tanpa pembayaran atau dengan tarif diskon.</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal"><b>“Konten Pengguna”</b> berarti konten dan data yang diunggah Pengguna ke Layanan, termasuk namun tidak terbatas pada kuis, gambar, video, teks, pesan, informasi, umpan balik pengguna, dan konten lainnya.</h4>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="pengunaan-layanan mt-4 mr-2">
                                    <h3>Penggunaan Layanan</h3>
                                    <div class="pharagraph mt-2">
                                        <h4 class="font-weight-normal">Situs web SOCA (<b>"Layanan"</b>) adalah layanan yang dihosting yang dioperasikan oleh SOCA AI (disebut sebagai <b>"SOCA"</b>). Setiap penggunaan Layanan tunduk pada Syarat dan Ketentuan Penggunaan berikut (<b>"Syarat dan Ketentuan"</b>), serta Kebijakan Privasi Soca, yang semuanya digabungkan dengan referensi ke dalam Syarat dan Ketentuan ini. Penggunaan Layanan oleh Anda merupakan penerimaan Anda terhadap syarat dan ketentuan ini.</h4>
                                    </div>
                                    <div class="pharagraph mt-2">
                                        <h4 class="font-weight-normal mt-3">1. <b>Kelayakan :</b> Dengan menggunakan Layanan, Anda menyatakan dan menjamin bahwa: (a) semua informasi pendaftaran yang Anda kirimkan adalah benar dan akurat; (b) Anda akan menjaga keakuratan informasi tersebut; dan, (c) penggunaan Layanan oleh Anda tidak melanggar hukum atau peraturan yang berlaku.</h4>
                                        <h4 class="font-weight-normal mt-3">2. <b>Akun dan Data Soca Anda:</b> Jika Anda membuat akun di Layanan, Anda bertanggung jawab untuk menjaga keamanan akun dan data Anda, dan Anda bertanggung jawab penuh atas semua aktivitas yang terjadi di bawah akun tersebut. Anda harus segera memberi tahu Soca tentang penggunaan data Anda, akun Anda, atau pelanggaran keamanan lainnya yang tidak sah. Soca tidak akan bertanggung jawab atas tindakan atau kelalaian apa pun oleh Anda, termasuk segala kerusakan dalam bentuk apa pun yang terjadi sebagai akibat dari tindakan atau kelalaian tersebut. Soca dapat dari waktu ke waktu menetapkan batas penyimpanan untuk data Anda, atau mengambil tindakan lain yang dianggap tepat oleh Soca untuk mengelola Layanan. Soca juga dari waktu ke waktu dapat mengubah kebijakannya dalam menawarkan konten komersial atau menampilkan iklan, dan dapat melakukannya tanpa pemberitahuan.</h4>
                                        <h4 class="font-weight-normal mt-3">3. <b>Konten dan Aktivitas yang Dilarang, dan Tanggung Jawab Pencipta:</b> Jika Anda membuat kuis, berkomentar melalui kotak diskusi, memposting materi ke Layanan, memposting tautan di Layanan, atau menyediakan materi melalui Layanan (materi apa pun, "Konten"), Anda sepenuhnya bertanggung jawab atas konten, dan segala kerugian yang diakibatkan, Konten tersebut. Itu terjadi terlepas dari apakah Konten yang dimaksud merupakan teks, grafik, file audio, perangkat lunak komputer atau format lain di mana Soca menyimpan data. Anda juga sepenuhnya bertanggung jawab untuk memastikan bahwa Anda berhak secara hukum untuk menggunakan informasi dan konten apa pun. Soca tidak bertanggung jawab atas kerusakan yang disebabkan oleh tindakan, kesalahan, kelalaian, atau kelalaian Anda sehubungan dengan konten yang Anda posting ke Soca.</h4>
                                        <h4 class="font-weight-normal mt-3">4. Layanan Soca dimaksudkan untuk mendidik. Berikut ini adalah contoh jenis Konten yang ilegal atau dilarang untuk diposting di atau melalui Layanan. Soca berhak untuk menangguhkan atau menghentikan kemampuan Anda untuk mengakses Soca jika Anda memposting konten ilegal atau terlarang di Soca. Soca selanjutnya dapat menyelidiki dan mengambil tindakan hukum yang sesuai terhadap siapa saja yang, atas kebijakan Soca sendiri, melanggar ketentuan ini, termasuk tanpa batasan, menghapus Konten yang menyinggung dari Layanan dan menghentikan akun pelanggar tersebut. Konten Terlarang termasuk, namun tidak terbatas pada, Konten yang, atas kebijakan Soca:</h4>
                                        <ul class="ml-4">
                                            <li>
                                                <h4 class="font-weight-normal">secara terang-terangan menyinggung dan mempromosikan rasisme, kefanatikan, kebencian, atau kerusakan fisik dalam bentuk apa pun terhadap kelas atau individu mana pun;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">melecehkan atau menganjurkan pelecehan terhadap orang lain;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">mengeksploitasi atau mendorong eksploitasi orang dengan cara seksual atau kekerasan;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">berisi segala sesuatu yang menjurus ke seksual, ketelanjangan yang tidak terkait dengan studi seni, kedokteran, atau bidang pendidikan bonafide lainnya, menggambarkan atau mengagungkan kekerasan berlebihan tanpa nilai pendidikan, atau merupakan materi pelajaran yang menyinggung;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">meminta informasi pribadi dari siapa pun yang berusia di bawah 18 tahun;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">memposting informasi secara publik yang menimbulkan atau menciptakan risiko privasi atau keamanan bagi siapa pun;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">termasuk informasi tentang orang lain yang telah Anda posting tanpa persetujuan orang itu;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">melanggar hak privasi, hak publisitas, hak cipta, hak merek dagang, hak kontrak, atau hak lain apa pun dari siapa pun;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">merupakan atau mempromosikan informasi yang Anda tahu salah atau menyesatkan atau mempromosikan kegiatan atau perilaku ilegal yang kasar, mengancam, cabul, memfitnah, atau mencemarkan nama baik;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">merupakan atau mempromosikan salinan ilegal atau tidak sah dari karya berhak cipta orang lain;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">meminta kata sandi atau informasi pengenal pribadi untuk tujuan komersial atau melanggar hukum dari Pengguna lain;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">melibatkan pengiriman "surat sampah", "surat berantai", atau surat massal yang tidak diminta, pesan instan, atau "spam";</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">memajukan atau mempromosikan aktivitas atau perusahaan kriminal atau memberikan informasi instruksional tentang aktivitas ilegal termasuk, namun tidak terbatas pada, membuat atau membeli senjata ilegal, melanggar privasi seseorang, atau atau menyediakan atau membuat virus komputer; atau</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">melibatkan kegiatan komersial dan/atau penjualan tanpa persetujuan tertulis sebelumnya dari Soca seperti kontes, undian, barter, iklan, atau skema piramida.</h4>
                                            </li>
                                        </ul>
                                        <h4 class="font-weight-normal mt-3">5. Berikut ini adalah contoh jenis aktivitas yang ilegal atau dilarang di Layanan. Soca berhak untuk menyelidiki dan mengambil tindakan hukum yang sesuai terhadap siapa saja yang, atas kebijakan Soca sendiri, melanggar ketentuan ini, termasuk tanpa batasan, melaporkan Anda ke penegak hukum atau otoritas keamanan nasional. Kegiatan yang dilarang termasuk, namun tidak terbatas pada:</h4>
                                        <ul class="ml-4">
                                            <li>
                                                <h4 class="font-weight-normal">kegiatan kriminal atau menyiksa, termasuk pornografi anak, penipuan, perdagangan materi cabul, perdagangan narkoba, perjudian, pelecehan, penguntit, spamming, pengiriman virus atau file berbahaya lainnya, pelanggaran hak cipta, pelanggaran paten, atau pencurian rahasia dagang;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">menghindari atau memodifikasi, mencoba untuk mengelak atau memodifikasi, atau mendorong atau membantu orang lain dalam mengakali atau memodifikasi teknologi keamanan atau perangkat lunak yang merupakan bagian dari Layanan;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">mengorganisir atau mendorong tindakan kekerasan;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">meniru atau mencoba menyamar sebagai pengguna, orang, atau entitas lain;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">menggunakan akun, nama pengguna, atau kata sandi pengguna lain setiap saat atau mengungkapkan kata sandi Anda kepada pihak ketiga mana pun atau mengizinkan pihak ketiga mana pun untuk mengakses akun Anda;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">menggunakan informasi apa pun yang diperoleh dari Layanan untuk melecehkan, menyalahgunakan, atau merugikan orang atau entitas lain, atau mencoba melakukan hal yang sama;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">aktivitas yang melibatkan penggunaan virus, bot, worm, atau kode, file, atau program komputer lainnya yang mengganggu, merusak, atau membatasi fungsionalitas perangkat lunak atau perangkat keras komputer, atau dengan cara lain mengizinkan penggunaan atau akses yang tidak sah ke komputer atau jaringan komputer;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">penggunaan sistem secara otomatis, seperti, namun tidak terbatas pada, menggunakan skrip untuk membuat atau memposting Konten;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">mengganggu, mengganggu, atau membuat beban yang tidak semestinya pada Layanan atau jaringan atau layanan yang terhubung ke Layanan;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">menampilkan iklan komersial yang tidak sah pada Layanan, atau menerima pembayaran atau sesuatu yang berharga dari orang ketiga sebagai ganti Anda melakukan aktivitas komersial apa pun melalui penggunaan Layanan yang tidak sah atau tidak diizinkan atas nama orang itu;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">menggunakan Layanan dengan cara yang tidak sesuai dengan setiap dan semua hukum dan peraturan yang berlaku; atau</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">mereproduksi, menggandakan, menyalin, menjual, memperdagangkan, menjual kembali, atau mengeksploitasi untuk tujuan komersial apa pun, setiap bagian atau penggunaan, atau akses ke, Konten dan/atau layanan yang ditawarkan di seluruh Layanan ini (kecuali jika Anda secara khusus diizinkan untuk melakukannya dalam perjanjian tersendiri).</h4>
                                            </li>
                                        </ul>
                                        <h4 class="font-weight-normal mt-3">6. Dengan menyediakan Konten, Anda menyatakan dan menjamin bahwa:</h4>
                                        <ul class="ml-4">
                                            <li>
                                                <h4 class="font-weight-normal">pengunduhan, penyalinan, dan penggunaan Konten tidak akan melanggar hak kepemilikan, termasuk namun tidak terbatas pada hak cipta, paten, merek dagang, atau hak rahasia dagang, dari pihak ketiga mana pun;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">jika pemberi kerja Anda memiliki hak atas kekayaan intelektual yang Anda buat, Anda telah: (i) menerima izin dari pemberi kerja Anda untuk memposting atau menyediakan Konten, termasuk namun tidak terbatas pada perangkat lunak apa pun; atau (ii) mendapatkan pengabaian dari pemberi kerja Anda atas semua hak dalam atau atas Konten;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">Anda telah sepenuhnya mematuhi lisensi pihak ketiga mana pun yang terkait dengan Konten, dan telah melakukan semua hal yang diperlukan untuk berhasil menyampaikan persyaratan yang diperlukan kepada pengguna akhir;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">konten tidak mengandung atau menginstal virus, worm, malware, Trojan horse, atau konten berbahaya atau merusak lainnya;</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">konten bukan spam, dan tidak mengandung konten komersial yang tidak etis atau tidak diinginkan yang dirancang untuk mengarahkan lalu lintas ke situs pihak ketiga atau meningkatkan peringkat mesin pencari situs pihak ketiga, atau untuk melanjutkan tindakan melanggar hukum (seperti phishing) atau menyesatkan penerima tentang sumber materi (seperti spoofing);</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">konten tidak cabul atau memfitnah, dan tidak melanggar privasi atau hak publisitas pihak ketiga mana pun; dan</h4>
                                            </li>
                                            <li>
                                                <h4 class="font-weight-normal">memiliki, dalam hal Konten yang menyertakan kode komputer, dikategorikan dan/atau dijelaskan secara akurat jenis, sifat, penggunaan, dan efek materi, baik yang diminta oleh Soca atau lainnya.</h4>
                                            </li>
                                        </ul>
                                        <h4 class="font-weight-normal mt-3">7. <b>Tanggung Jawab Pengunjung Layanan:</b> Soca belum meninjau, dan tidak dapat meninjau, semua materi yang diposting ke Layanan, dan oleh karena itu tidak dapat bertanggung jawab atas konten, penggunaan, atau efek materi tersebut. Dengan mengoperasikan Layanan, Soca tidak menyatakan atau menyiratkan bahwa ia mendukung materi yang diposting di sana, atau meyakini materi tersebut akurat, berguna, atau tidak berbahaya. Anda bertanggung jawab untuk mengambil tindakan pencegahan yang diperlukan untuk melindungi diri Anda dan sistem komputer Anda dari virus, worm, Trojan horse, dan konten berbahaya atau merusak lainnya. Layanan dapat berisi konten yang menyinggung, tidak senonoh, atau tidak menyenangkan, serta konten yang mengandung ketidakakuratan teknis, kesalahan ketik, dan kesalahan lainnya. Layanan juga dapat berisi materi yang melanggar privasi atau hak publisitas, atau melanggar kekayaan intelektual dan hak kepemilikan lainnya, dari pihak ketiga, atau pengunduhan, penyalinan, atau penggunaan yang tunduk pada syarat dan ketentuan tambahan, dinyatakan atau tidak dinyatakan. Soca melepaskan tanggung jawab apa pun atas kerugian apa pun yang diakibatkan oleh penggunaan oleh pengunjung Layanan, atau dari pengunduhan apa pun oleh pengunjung konten yang diposting di sana.</h4>
                                        <h4 class="font-weight-normal mt-3">8. <b>Konten yang Diposting di Situs Web Lain:</b> Kami belum meninjau, dan tidak dapat meninjau, semua materi, termasuk perangkat lunak komputer, yang tersedia melalui situs web dan halaman web yang ditautkan oleh Soca, dan yang ditautkan ke Soca. Soca tidak memiliki kendali atas situs web dan halaman web non-Soca tersebut, dan tidak bertanggung jawab atas konten atau penggunaannya. Dengan menautkan ke situs web atau halaman web non-Soca, Soca tidak menyatakan atau menyiratkan bahwa Soca mendukung situs web atau halaman web tersebut. Anda bertanggung jawab untuk mengambil tindakan pencegahan yang diperlukan untuk melindungi diri Anda dan sistem komputer Anda dari virus, worm, Trojan horse, dan Konten berbahaya atau merusak lainnya. Soca melepaskan tanggung jawab apa pun atas kerugian apa pun yang diakibatkan oleh penggunaan Anda atas situs web dan halaman web non-Soca.</h4>
                                        <h4 class="font-weight-normal mt-3">9. <b>Perubahan:</b> Layanan, termasuk namun tidak terbatas pada semua konten yang tersedia dan Syarat dan Ketentuan ini, dapat diubah atas kebijakan Soca dan tanpa pemberitahuan. Anda terikat oleh setiap pembaruan atau perubahan tersebut, termasuk namun tidak terbatas pada hal-hal yang memengaruhi Syarat dan Ketentuan ini, dan karenanya harus meninjau Syarat dan Ketentuan ini secara berkala.</h4>
                                        <h4 class="font-weight-normal mt-3">10. <b>Batasan jaminan Soca, pemasoknya, dan pemberi lisensinya:</b> Kecuali dinyatakan lain secara tegas, semua konten yang diposting ke atau tersedia dari Layanan disediakan "sebagaimana adanya", dan Soca, pemasoknya, dan pemberi lisensinya tidak membuat pernyataan atau jaminan, secara tegas atau tersirat, termasuk namun tidak terbatas pada jaminan dapat diperjualbelikan, kesesuaian untuk tujuan tertentu, kepemilikan, atau non-pelanggaran hak kepemilikan. Anda memahami dan setuju bahwa Anda mengunduh dari, atau mendapatkan konten atau layanan melalui, Layanan atas kebijaksanaan dan risiko Anda sendiri, dan bahwa Soca, pemasoknya, dan pemberi lisensinya tidak akan memiliki kewajiban atau tanggung jawab apa pun atas kerusakan apa pun pada sistem atau data komputer Anda. yang dihasilkan dari pengunduhan atau penggunaan konten atau layanan tersebut. Beberapa yurisdiksi mungkin tidak mengizinkan pengecualian jaminan tersirat, sehingga beberapa hal di atas mungkin tidak berlaku untuk Anda.</h4>
                                        <h4 class="font-weight-normal mt-3">11. <b>Pernyataan Umum dan Jaminan:</b> Anda menyatakan dan menjamin bahwa penggunaan Layanan oleh Anda akan sesuai dengan Kebijakan Privasi Soca, dengan Syarat dan Ketentuan ini, dengan hukum dan peraturan yang berlaku, termasuk namun tidak terbatas pada hukum atau peraturan setempat di negara, negara bagian Anda , kota, atau wilayah pemerintah lainnya, mengenai perilaku online dan konten yang dapat diterima.</h4>
                                        <h4 class="font-weight-normal mt-3">12 .<b>Lain-lain:</b> Syarat dan Ketentuan ini merupakan keseluruhan perjanjian antara Soca dan Anda mengenai materi pokok perjanjian ini, dan hanya dapat diubah dengan amandemen tertulis yang ditandatangani oleh eksekutif Soca yang berwenang, atau dengan memposting versi revisi oleh Soca. Syarat dan Ketentuan ini akan mengikat dan akan berlaku untuk kepentingan para pihak, penerus mereka, dan penerima hak yang diizinkan.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import $ from "jquery"

export default {
    mounted() {
        $("section.terms").parent().addClass('mx-2');
    }
}
</script>

<style scoped>
.terms h3 {
    font-size: 24px;
}

.terms ul li::marker{
    font-size: 1.5em;
}

.terms .pharagraph h4{
    font-size: 20px;
    line-height: 27px;
}

.card {
    height: 80vh;
    width: 50rem;
    border-radius: 40px
}

.content-terms {
    height: 60vh;
}

.content-terms::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.content-terms::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 7px;
}
        
/* Handle */
.content-terms::-webkit-scrollbar-thumb {
    background: #B6B6B6; 
    border-radius: 7px;
}

/* Handle on hover */
.content-terms::-webkit-scrollbar-thumb:hover {
    background: #888; 
}
</style>